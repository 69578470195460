<template>
    <div class="scenarios">
        <div v-if="is_loading">{{ $t('Loading...') }}</div>
        <div v-else>
            <table class="table" style="max-width: 700px;">
                <thead>
                    <th>Select</th>
                    <th>Scenario</th>
                    <th>Impact consequence</th>
                </thead>
                <tbody>
                    <tr v-for="scn in scenarios">
                        <td><input v-model="scn.is_selected" @change="save(scn)" type="checkbox"></td>
                        <td><input v-model="scn.name" @change="save(scn)" class="form-control"></td>
                        <td><textarea v-model="scn.consequence" @change="save(scn)" class="form-control" /></td>
                    </tr>
                </tbody>
            </table>
            
            <div class="mt-4" style="max-width: 720px;">
                <hr>
                <h3>Add new scenario</h3>
                <table style="width: 100%;">
                    <tr>
                        <td style="vertical-align: top;" class="p-1">
                            Scenario:
                            <br>
                            <input v-model="new_item.name" class="form-control" type="text">
                        </td>
                        <td class="p-1">
                            Impact consequence:
                            <br>
                            <textarea v-model="new_item.consequence" class="form-control" />
                        </td>
                        <td>
                            <button @click="create_new" class="btn btn-primary" :disabled="!new_item.name || !new_item.consequence">Create</button>
                        </td>
                    </tr>
                </table>

            </div>
        </div>


    </div>
</template>

<script>

import axios from 'axios'


export default {
    name: 'Scenarios',
    data() {
        return {
            account_id: ACCOUNT_ID,
            asmt_id: ASMT_ID,

            is_loading: true,

            scenarios: [],

            new_item: {
                is_selected: true,
                name: '',
                consequence: '',
            }
        }
    },
    mounted() {
        axios
            .get(`/api/asmt/${this.account_id}/${this.asmt_id}/scenarios`)
            .then(r => {
                this.is_loading = false;
                this.scenarios = r.data;
            })
    },
    computed: {
      
    },
    methods: {
        async create_new() {
            const r = await axios.post(`/api/asmt/${this.account_id}/${this.asmt_id}/scenarios`, this.new_item);
            this.scenarios.push(r.data);
            this.new_item.name = ''
            this.new_item.consequence = ''
        },
        async save(scn) {
            const r = await axios.patch(`/api/asmt/${this.account_id}/${this.asmt_id}/scenarios/${scn.id}`, scn);
        }
    },
    components: {
        //Modal,
    }

}
</script>

import Vue from 'vue'

import AssetDefinitions from './components/AssetDefinitions'
import Scenarios from './components/Scenarios'
import Maturity from './components/Maturity'
import Criticality from './components/Criticality'
import Compare from './components/Compare'
import MaturityAssessment from './components/MaturityAssessment'
import RVR from './components/RVR'
import RRTC from './components/RRTC'
import AdminMChoices from './components/admin/AdminMChoices'
import i18n from './i18n'
import store from './store'





Vue.config.productionTip = false


function mount_component(component, element) {
  new Vue({
    store,
    i18n,
    render: h => h(component)
  }).$mount(element)
}

// Exports to browser:

window.mount_component = mount_component
window.components = {
  AssetDefinitions,
  Scenarios,
  Maturity,
  Criticality,
  Compare,
  MaturityAssessment,
  RVR,
  RRTC,
  AdminMChoices,
}

<template>
    <div class="rrtc">
        <h2>{{ $t('Residual Risk Treatment Considerations') }}</h2>

        <AssetSwitch :assets="assets" v-model="current_asset" />

        {{ $t('Maturities marked as accepted risk.') }}
        

        <div v-if="current_asset">
            {{ $t('Asset Impact Criticality') }}: 
                <strong>{{current_asset.criticality_score}} {{current_asset.criticality_category}}</strong>
            
            <table class="table table-sm table-hover mt-1" style="width: 1300px;">
            <thead>
                <tr>
                    <th style="width: 500px;">{{ $t('Maturity') }}</th>
                    <th style="width: 100px;">{{ $t('Action') }}</th>
                    <th style="width: 200px;"></th>
                    <th style="width: 200px;"></th>
                    <th style="width: 200px">{{ $t('New maturity') }}</th>
                </tr>
            </thead>
            <tbody v-for="(items, cat) in maturiteis_by_cateogry">
                <tr class="bg-light">
                    <td colspan="6"><h4>{{ cat }}</h4></td>
                </tr>
                <tr v-for="maturity in items">
                    <td>
                        <div :class="get_color(maturity)" class="p-1" style="width: 250px; float: right;">
                            {{ maturity.value }}
                        </div>
                        <strong>{{ maturity.control.name }}</strong>
                        <br>
                        <i>{{ maturity.action_notes }}</i>
                    </td>
                    <td>
                        {{ maturity.action | action_text }}
                    </td>
                    <td>
                        <button 
                            @click="agree(maturity)"
                            :class="{'btn-success': maturity.approve_accept === true, 'btn-light': maturity.approve_accept !== true }"
                            class="btn btn-sm">{{ $t('Agree') }}</button>
                        <button 
                            @click="not_agree(maturity)"
                            :class="{'btn-warning': maturity.approve_accept === false, 'btn-light': maturity.approve_accept !== false }"
                            class="btn btn-sm">{{ $t('Not agree') }}</button>
                    </td>
                    <td>
                        <button @click="input_accept_notes(maturity)" class="btn btn-sm btn-link">
                            <span v-if="maturity.approve_accept_notes">{{ maturity.approve_accept_notes|str_limit(55) }}</span>
                            <div v-else class="text-dark border p-1">{{ $t('notes...') }}</div>
                        </button>
                    </td>
                    <td>
                        <select v-if="maturity.approve_accept == true" 
                            v-model="maturity.approve_new_value"
                            @change="select_new_maturity(maturity)"
                            class="form-control form-control-sm">
                                <option v-for="ch in mew_mat_choices(maturity.control.category_id)">{{ ch.value }}</option>
                        </select>
                    </td>
                </tr>
            </tbody>
            </table>
        </div>

        <!-- Modal -->
        <div v-if="show_modal & popup_maturity != null" class="modal" id="exampleModal" tabindex="-1" style="display: block;">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{ $t('Set note...') }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" @click="show_modal = false">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <textarea v-model.lazy="popup_maturity.approve_accept_notes" class="form-control"></textarea>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="show_modal = false">{{ $t('Close') }}</button>
                <button type="button" class="btn btn-primary" @click="save_notes">{{ $t('Save changes') }}</button>
            </div>
            </div>
        </div>
        </div>

    </div>

</template>

<script>

import axios from 'axios'
import AssetSwitch from './AssetSwitch.vue'
import {COLORS} from './common.js'

export default {
    name: 'RRTC',
    data() {
        return {
            account_id: RRTC_DATA.account_id,
            asmt_id: RRTC_DATA.asmt_id,
            assets: RRTC_DATA.assets,
            current_asset: RRTC_DATA.assets[0],
            maturity_categories: RRTC_DATA.maturity_categories,
            popup_maturity: null,
            show_modal: false,

        }
    },
    computed: {
        maturiteis_by_cateogry() {
            return this.current_asset.maturities.reduce((acc, value) => {
                if (!acc[value.control.category.name]) {
                    acc[value.control.category.name] = []
                }

                acc[value.control.category.name].push(value)

                return acc
            }, {})
        }
    },
    methods: {
        mew_mat_choices(category_id) {
            let cat = this.maturity_categories.find(cat => cat.id == category_id)
            return cat.choices
        },
        select_new_maturity(mat) {
            let choices = this.mew_mat_choices(mat.control.category_id)
            let score = choices.find(ch => ch.value == mat.approve_new_value).score
            mat.approve_new_score = score
            this.save(mat)
        },
        get_color(maturity) {
            return COLORS[maturity.color]
        },
        input_accept_notes(maturity) {
            this.popup_maturity = maturity
            this.show_modal = true
        },
        agree(maturity) {
            maturity.approve_accept = true
            this.save(maturity)
        },
        not_agree(maturity) {
            maturity.approve_accept = false
            this.save(maturity)
        },
        save_notes() {
            this.show_modal = false
            this.save(this.popup_maturity)
        },
        save(maturity) {
            axios
                .post(`/api/asmt/${this.account_id}/${this.asmt_id}/maturity/rrtc`, {
                    maturity_id: maturity.id,
                    approve_accept: maturity.approve_accept,
                    approve_accept_notes: maturity.approve_accept_notes,
                    approve_new_value: maturity.approve_new_value,
                    approve_new_score: maturity.approve_new_score,
                })
                .then(r => {
                    if (!r.data.success) {
                        alert('Falied to save reason')
                    }
                })
        }
    },
    filters: {
        action_text(value) {
            if (value == 'take') return 'Take action'
            if (value == 'accept') return 'Accept'
            return value
        }
    },
    components: {
        AssetSwitch,
    }
}
</script>

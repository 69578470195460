<template>
    <div class="asset-switch">
        <strong v-if="current_asset">
            {{ current_asset.name }}
        </strong>
        <button @click="show_modal=true" class="btn btn-link" style="border: 1px solid #f0f0f0;">...</button>
        <!-- <ul class="nav nav-pills">
            <li class="nav-item" v-for="asset in assets">
                <a class="nav-link" :class="{active: asset.id == value.id}" @click="select_asset(asset)" href="#">{{ asset.name }}</a>
            </li>
        </ul> -->

        <Modal title="Switch asset" v-if="show_modal" @close="show_modal = false">
            <ul class="nav nav-pills">
                <li class="nav-item" v-for="asset in assets">
                    <a class="nav-link border mr-2" @click="select_asset(asset)" href="#">{{ asset.name }}</a>
                </li>
            </ul>
        </Modal>
    </div>
</template>


<script>
import Modal from './Modal.vue'

export default {
    name: 'AssetSwitch',
    props: ['assets', 'value'],
    data() {
        return {
            show_modal: true,
            current_asset: null,
        }
    },
    mounted() {
        let current = window.localStorage.getItem('onrix-current-asset')
        if (current == null)  {
            this.show_modal = true
        }
        else{
            current = this.assets.find(a => a.id == current)
            this.select_asset(current)
        }
    },
    methods: {
        select_asset(asset) {
           window.localStorage.setItem('onrix-current-asset', asset.id)
           this.show_modal = false
           this.$emit('input', asset)
           this.current_asset = asset
        }
    },
    components: {
        Modal,
    }
}
</script>

<template>
    <div class="question">
        ?
        <span class="tooltiptext">{{ text }}</span>
    </div>
</template>


<script>
export default {
    name: 'QTooltip',
    props: ['text'],
}
</script>

<style>

.question {
    position: relative;
    display: inline-block;
    background-color: #ccc;
    padding: 0px 5px 0px 5px;
}

.question .tooltiptext {
    visibility: hidden;
    width: 400px;
    background-color: #fffeee;
    border: 1px solid #f0f0f0;
    box-shadow: 3px 3px 3px #ccc;
    padding: 5px;
    white-space: pre-wrap;
    font-size: 12px;
    font-weight: normal;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    left: 20px;
}

.question:hover .tooltiptext {
    visibility: visible;
}

.question {
    opacity: 0;
    float: right;
}
td:hover .question {
    opacity: 1;
}

</style>
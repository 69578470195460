<template>
    <div class="asset-definitions">
        <div v-if="is_loading">{{ $t('Loading...') }}</div>
        <div v-else>
            <table class="table table-sm table-hover">
                <thead>
                    <tr>
                        <th></th>
                        <th v-for="attr in attributes">{{ attr }}</th>
                    </tr>
                </thead>
                <tbody>
                <tr v-for="asset in assets" class="asset-row">
                    <td style="min-width: 200px;">
                        <strong>{{ asset.name }}</strong>
                        <button @click="open_edit(asset)" class="btn btn-sm btn-outline-secondary float-right">...</button>
                    </td>
                    <td v-for="attr in attributes">
                        <input 
                            v-model.lazy="asset.attributes[attr]"  
                            @change="on_attr_change(asset, attr)" 
                            @focus="$event.target.select()" 
                            class="form-control form-control-sm">
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

  
        <div class="input-group mb-3" style="width: 300px;">

            <input v-model.trim="new_asset" @keyup.enter="create_asset" class="form-control" :placeholder="$t('New asset...')">
            <div class="input-group-append">
                <button @click="create_asset" class="btn btn-secondary" v-show="new_asset">{{ $t('Create') }}</button>
            </div>
        </div>

        <Modal v-if="edit_asset" :title="edit_asset.name" @close="edit_asset = null">
            <input v-model="edit_asset_new_name" class="form-control">
            <button @click="save_name" type="submit" class="btn btn-primary mt-2">Save</button>
            <div class="text-center mt-4 mb-4">OR</div>
            <button @click="delete_asset" class="btn btn-danger">Delete this asset</button>
        </Modal>
    </div>
</template>

<script>

import axios from 'axios'
import Modal from './Modal.vue'

export default {
    name: 'AssetDefinitions',
    data() {
        return {
            account_id: ACCOUNT_ID,
            asmt_id: ASMT_ID,
            new_asset: "",
            assets: [],
            attributes: [],
            is_loading: true,

            edit_asset: null,
            edit_asset_new_name: '',
        }
    },
    mounted() {
        window.localStorage.setItem('onrix-current-asset', null) // Resetting asset selection
        axios
            .get(`/api/asmt/${this.account_id}/${this.asmt_id}/assets-definitions`)
            .then(r => {
                this.is_loading = false;
                this.assets = r.data.assets;
                this.attributes = r.data.attributes;
            })
    },
    computed: {
      
    },
    methods: {
        on_attr_change(asset, attr) {
            axios
                .post(`/api/asmt/${this.account_id}/${this.asmt_id}/assets-definitions`, {
                    asset_id: asset.id,
                    attribute: attr,
                    value: asset.attributes[attr],
                })
                .then(r => {
                    if (!r.data.success) {
                        alert('Falied to save attribute')
                    }
                })
        },
        async create_asset() {
            let resp = await axios.put(`/api/asmt/${this.account_id}/${this.asmt_id}/assets-definitions`, {
                name: this.new_asset
            })
            this.assets = resp.data.assets;
            this.attributes = resp.data.attributes;
            this.new_asset = "";

        },
        open_edit(asset) {
            this.edit_asset = asset
            this.edit_asset_new_name = asset.name
        },
        async save_name() {
            await axios.patch(`/api/asmt/${this.account_id}/${this.asmt_id}/asset/${this.edit_asset.id}`, {
                name: this.edit_asset_new_name
            })
            this.edit_asset.name = this.edit_asset_new_name
            this.edit_asset = null
        },
        async delete_asset() {
            let resp = await axios.delete(`/api/asmt/${this.account_id}/${this.asmt_id}/asset/${this.edit_asset.id}`)
            if (!resp.data.success) {
                alert(resp.data.message)
            } else {
                window.location.href = window.location.href
            }
            
        }
    },
    components: {
        Modal,
    }

}
</script>


<template>
    <div class="maturity-assessment">

        <AssetSwitch :assets="assets" v-model="current_asset" />

        {{ $t('Asset Impact Criticality') }}: <b>{{ current_asset.criticality_score }} {{ current_asset.criticality_score_category }}</b>


        <p>{{ $t('Please select maturities for Risk Vulnerabilities Remediation') }}</p>



        <div v-for="cat in maturity_categories">


            <table class="table table-sm table-hover mt-4">
                <tbody>
                    <tr class="bg-light">
                        <td style="width: 400px"><h4>{{ cat.name }}</h4></td>
                        <td class="text-right" style="width: 220px">{{ $t('Maturity Score') }}: <strong>{{ category_maturity_controls[cat.id].score.toFixed(2) }}</strong></td>
                        <td></td>
                    </tr>
                    <tr v-for="ctrl in category_maturity_controls[cat.id].items">
                        <td>
                            <label>
                                <input type="checkbox"
                                    v-if="ctrl.maturity.id"
                                    @change="save(ctrl.maturity)" 
                                    v-model="ctrl.maturity.is_selected">
                                {{ ctrl.control.name }}
                            </label>
                        </td>

                        <td>
                            <div :class="get_color_classes(ctrl.maturity)" class="p-1">
                                {{ctrl.maturity.value }}
                            </div>
                        </td>
                        <td>
                            {{ctrl.maturity.reason }}
                        </td>
      
                    </tr>

                </tbody>
            </table>

        </div> 

    


    </div>
</template>

<script>

import axios from 'axios'
import AssetSwitch from './AssetSwitch.vue'
import {COLORS} from './common.js'

export default {
    name: 'MaturityAssessment',
    data() {
        return {
            account_id: MAT_ASMT_DATA.account_id,
            asmt_id: MAT_ASMT_DATA.asmt_id,
            assets: MAT_ASMT_DATA.assets,
            maturity_categories: MAT_ASMT_DATA.maturity_categories,
            maturity_controls: MAT_ASMT_DATA.maturity_controls,
            current_asset: MAT_ASMT_DATA.assets[0]
        }
    },
    computed: {

        category_maturity_controls() {
            let result = {}

            this.maturity_categories.forEach(cat => {
                let items = []
                let score = 0
                let controls = this.maturity_controls.filter(ctrl => ctrl.category_id == cat.id)
                controls.forEach(ctrl => {
                    let maturity = this.get_maturity(ctrl.id)
                    if (maturity.score)
                        score += maturity.score
                    items.push({
                        'control': ctrl,
                        'maturity': maturity,
                    })
                })

                result[cat.id] = {'items': items, 'score': score}

            })

            return result
        },

    },
    methods: {

        get_maturity(ctrl_id) {
            let found_items = this.current_asset.maturity.filter(m => m.control_id == ctrl_id)
            if (found_items.length == 0) {
                return {'value': '----', 'color': 'none', 'score': 0}
            }
            return found_items[0]
        },
        get_color_classes(maturity) {
            return COLORS[maturity.color]
        },
        save(maturity) {
            axios
                .post(`/api/asmt/${this.account_id}/${this.asmt_id}/maturity/select`, {
                    maturity_id: maturity.id,
                    asset_id: this.current_asset.id,
                    is_selected: maturity.is_selected
                })
                .then(r => {
                    if (!r.data.success) {
                        alert('Falied to save answer')
                    }
                })
        }
    },

    components: {
        AssetSwitch,
    }

}
</script>

<template>
    <div class="criticality">

        <AssetSwitch :assets="assets" v-model="current_asset" />

        <div class="progress mt-2" style="height: 3px; width: 320px;">
            <div class="progress-bar" :class="progress_class" :style="{'width': progress + '%'}"></div>
        </div>
        <span v-if="progress >= 100">
            {{ $t('Asset Impact Criticality') }}: {{total_score.toFixed(2)}} - {{score_category}}
            <!--
            <br>
            {{ $t('Material Damange(Cost) and Financial Loss (Revenue)') }}: {{ebitda_score.toFixed(2)}} &euro; -->
            <span v-if="site.ebitda_notes">- {{site.ebitda_notes}}</span>

            <br>

            <button @click="show_ebitda_modal = true" class="btn btn-sm btn-success">EBITDA &euro;</button>


            <Modal title="EBITDA" v-if="show_ebitda_modal" @close="show_ebitda_modal = false">
                <table style="width: 100%;">
                <tr class="font-weight-bold border-bottom">
                    <td class="">Impact</td>
                    <td></td>
                    <td class="text-right">Material Damange / Financial Loss</td>
                </tr>
                <tr>
                    <td>Minor</td>
                    <td>({{site.risk_minor}}%)</td>
                    <td class="text-right">{{ (site.ebitda * site.risk_minor / 100).toLocaleString() }} &euro;</td>
                </tr>
                <tr>
                    <td>Low</td>
                    <td>({{site.risk_low}}%)</td>
                    <td class="text-right">{{ (site.ebitda * site.risk_low / 100).toLocaleString() }} &euro;</td>
                </tr>
                <tr>
                    <td>Moderate</td>
                    <td>({{site.risk_moderate}}%)</td>
                    <td class="text-right">{{ (site.ebitda * site.risk_moderate / 100).toLocaleString() }} &euro;</td>
                </tr>
                <tr>
                    <td>High</td>
                    <td>({{site.risk_high}}%)</td>
                    <td class="text-right">{{ (site.ebitda * site.risk_high / 100).toLocaleString() }} &euro;</td>
                </tr>
                <tr>
                    <td>Severe</td>
                    <td>({{site.risk_severe}}%)</td>
                    <td class="text-right">{{ (site.ebitda * site.risk_severe / 100).toLocaleString()  }} &euro;</td>
                </tr>
                </table>
            </Modal>
            
            
        </span>

        <!-- Grid -->

        <div class="row">
            <div class="col-10">
                <div v-for="domain in domains">
                    <h5 class="mt-4 domain-title">
                        {{ domain.name }}
                        <QTooltip v-if="domain.notes" :text="domain.notes" class="domain-tooltip" />
                    </h5>
                    
                    <table class="table table-sm table-borderless table-striped table-hover">
                        <tbody v-for="level in domain.levels" @mouseover="hover_level = level" @mouseleave="hover_level = null">
                            <tr>
                                <td>
                                    {{ level.name }}
                                    <div class="question" v-if="level.notes">
                                        ?
                                        <span class="tooltiptext">{{level.notes}}</span>
                                    </div>
                                </td>
                                <!-- <td style="width: 100px;">
                                    <button v-if="selected_map[`${level.id}_${domain.id}`]" @click="input_reason(level, domain)" class="btn btn-sm btn-link">
                                        <span v-if="selected_map[`${level.id}_${domain.id}`].reason">{{ selected_map[`${level.id}_${domain.id}`].reason|str_limit(5) }}</span>
                                        <span v-else class="text-danger">{{ $t('Explain...') }}</span>
                                    </button>
                                </td> -->
                                <td v-for="choice in level.choices" style="width: 160px;">
                                    <button type="button"
                                        @mouseover="hover_choice = choice"
                                        @mouseleave="hover_choice = null"
                                        @click="select(level, domain, choice)"
                                        :class="{'btn-primary': is_selected(level, domain, choice), 'btn-outline-secondary': !is_selected(level, domain, choice)}"
                                        class="btn btn-sm btn-block">
                                            {{ choice.label }}
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td :colspan="2 + domain.levels[0].choices.length">
                                    <template v-if="selected_map[`${level.id}_${domain.id}`]">
                                        <i>{{ selected_map[`${level.id}_${domain.id}`].reason }}</i>
                                        <button v-if="selected_map[`${level.id}_${domain.id}`]" @click="input_reason(level, domain)" class="btn btn-sm btn-link">
                                            <span v-if="selected_map[`${level.id}_${domain.id}`].reason">edit</span>
                                            <span v-else class="text-danger">{{ $t('Explain...') }}</span>
                                        </button>
                                    </template>
                                    <div v-else style="opacity: 0.4;">
                                        <i>{{ $t('No answer') }}</i>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-2">
                <div class="helptooltip" v-if="hover_choice || hover_level">
                    <div v-if="hover_level">
                        <strong>{{ hover_level.name }}</strong><br>
                        {{ hover_level.notes }}
                    </div>

                    <div v-if="hover_choice" class="pt-2">
                        <strong>{{ hover_choice.name }}</strong><br>
                        {{ hover_choice.definition }}
                    </div>
                    
                </div>
            </div>
        </div>




        <!-- Modal -->
        <div v-if="reason_modal.show" class="modal" id="exampleModal" tabindex="-1" style="display: block;">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Set reason...</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" @click="reason_modal.show = false">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <textarea v-model="reason_modal.reason" class="form-control"></textarea>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="reason_modal.show = false">Close</button>
                <button type="button" class="btn btn-primary" @click="save_reason">Save changes</button>
            </div>
            </div>
        </div>
        </div>
        
    </div>

</template>

<script>

import axios from 'axios'
import AssetSwitch from './AssetSwitch.vue'
import QTooltip from './QTooltip.vue'
import Modal from './Modal.vue'


export default {
    name: 'Criticality',
    data() {
        return {
            account_id: CRITICALITY_DATA.account_id,
            asmt_id: CRITICALITY_DATA.asmt_id,
            assets: CRITICALITY_DATA.assets,
            domains: CRITICALITY_DATA.domains,
            site: CRITICALITY_DATA.site,
            //levels: CRITICALITY_DATA.levels,
            current_asset: CRITICALITY_DATA.assets[0],
            hover_choice: null,
            hover_level: null,
            reason_modal: {
                show: false,
                reason: '',
                level: null,
                domain: null,
            },
            show_ebitda_modal: false,
        }
    },
    computed: {
        selected_map() {
            let result = {}
            this.current_asset.criticality.forEach(cr => {
                let key = `${cr.level_id}_${cr.domain_id}`
                result[key] = cr
            })
            return result
        },
        progress() {
            let total = 0;
            let selected = 0;
            this.domains.forEach(d => {
                d.levels.forEach(l => {
                    total += 1
                    if (this.selected_value(l, d) !== null)
                        selected += 1
                })
            })
            return selected * 100 / total 
        },
        progress_class() {
            if (this.progress < 100) return 'bg-warning'
            return 'bg-success'
        },
        total_score() {
            let sum_scores = this.current_asset.criticality.reduce((total, current) => {
                return total + current.score
            }, 0)
            return sum_scores / this.num_leveles
        },
        score_category() {
            if (this.progress < 100) return null;
            if (this.total_score < 3) return this.$t('Low impact');
            if (this.total_score < 6) return this.$t('Median impact');
            if (this.total_score < 9) return this.$t('High-impact');
            return this.$t('Severe impact');
        },
        ebitda_score() {
            if (this.progress < 100) return null;
            if (this.total_score == 0) return this.site.ebitda * this.site.risk_minor / 100;
            if (this.total_score < 3) return this.site.ebitda * this.site.risk_low / 100;
            if (this.total_score < 6) return this.site.ebitda * this.site.risk_moderate / 100;
            if (this.total_score < 9) return this.site.ebitda * this.site.risk_high / 100;
            return this.site.ebitda * this.site.risk_severe / 100;
        },
        num_leveles() {
            // returns number of unique levels
            let level_ids = this.domains.reduce((res, d) => {
                d.levels.forEach(l => res.add(l.id))
                return res
            }, new Set())
            return level_ids.size
        }
    },
    methods: {
        input_reason(level, domain) {
            this.reason_modal.show = true
            this.reason_modal.reason = this.selected_map[`${level.id}_${domain.id}`].reason
            this.reason_modal.level = level
            this.reason_modal.domain = domain
        },
        save_reason() {
            this.reason_modal.show = false
            const l = this.reason_modal.level
            const d = this.reason_modal.domain
            this.selected_map[`${l.id}_${d.id}`].reason = this.reason_modal.reason
            axios
                .post(`/api/asmt/${this.account_id}/${this.asmt_id}/criticality/reason`, {
                    answer_id: this.selected_map[`${l.id}_${d.id}`].id,
                    asset_id: this.current_asset.id,
                    reason: this.reason_modal.reason
                })
                .then(r => {
                    if (!r.data.success) {
                        alert('Falied to save answer')
                    }
                })
        },
        is_selected(level, domain, choice) {
            // console.info(['choice.name', choice.id, this.selected_value(level, domain)])
            return (this.selected_value(level, domain) == choice.name)
        },
        selected_value(level, domain) {
            let key = `${level.id}_${domain.id}`
            let item = this.selected_map[key]
            if (item == undefined) return null
            // console.info(['selected value', item])
            return item.value
        },
        select(level, domain, choice) {
            let answer = this.current_asset.criticality.find(c => c.level_id == level.id && c.domain_id == domain.id)
            if (answer == undefined) {
                answer = {level_id: level.id, domain_id: domain.id, value: choice.name, score: choice.score}
                this.current_asset.criticality.push(answer)
            } else {
                answer.value = choice.name
                answer.score = choice.score
            }

            axios
                .post(`/api/asmt/${this.account_id}/${this.asmt_id}/criticality/answer`, {
                    asset_id: this.current_asset.id,
                    choice_id: choice.id,
                })
                .then(r => {
                    if (!r.data.success) {
                        alert('Falied to save answer')
                    }
                })
        },
        // domain_score(domain) {
        //     let domain_values = this.current_asset.criticality.filter(c => c.domain_id == domain.id)
        //     if (domain_values.length == 0) {
        //         return 0;
        //     }
        //     let sum_score = domain_values.reduce((total, current) => {
        //         return total + current.score
        //     }, 0)
        //     return sum_score / domain_values.length;
        // },
    },
    components: {
        AssetSwitch,
        QTooltip,
        Modal,
    }
}
</script>

<style scoped>
    .helptooltip {
        
        position: fixed;
        right: 18px;
        top: 120px;
        z-index: 10;
        width: 280px;
        padding: 2px 6px;
        background-color: #fffeee;
        border: 1px solid #f0f0f0;
        border-radius: 4px;
        box-shadow: 3px 3px 3px #ccc;
        font-size: 0.8rem;
        white-space: pre-line;
    }
    .domain-title .question {
        float: none;
        margin-left: 10px;
        font-size: 0.9rem;
        font-weight: normal;
    }
    .domain-title:hover .question {
        opacity: 1;
    }

</style>

<template>
    <div class="maturity">


        <AssetSwitch :assets="assets" v-model="current_asset" />

        <!-- Grid -->

        <div class="row">
            <div class="col-10">
                <table class="table table-sm table-borderless table-striped table-hover">
                    <thead>
                        <tr>
                            <th>
                                {{ $t('Maturity score') }}:
                                <strong v-if="total_score">{{ total_score.toFixed(2) }}</strong>
                                <small v-if="!total_score">{{ $t('Please select all answers') }}:</small>
                            </th>
                            <th v-for="choice in choices" style="width: 150px;" :class="{'bg-light': hover_choice == choice}">{{ choice.value_label }}</th>
                        </tr>
                    </thead>
                    <tbody v-for="ctrl in controls">
                        <tr>
                            <td>
                                {{ ctrl.name }}
                            </td>

                            <td v-for="choice in choices"
                                @mouseover="hover_choice = {'choice': choice, 'control': ctrl}"
                                @mouseleave="hover_choice = null" 
                            >
                                <button type="button" 
                                    @click="select(ctrl, choice)"
                                    :class="{'btn-primary': is_selected(ctrl, choice), 'btn-outline-secondary': !is_selected(ctrl, choice)}"
                                    class="btn btn-sm btn-block sel" >
                                        {{ $t('Select') }}
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td :colspan="1 + choices.length">
                                <span v-if="selected_map[ctrl.id]">{{ selected_map[ctrl.id].reason }}</span>
                                <button @click="input_reason(ctrl)" v-if="selected_map[ctrl.id]" class="btn btn-sm btn-link">
                                    <span v-if="selected_map[ctrl.id].reason">edit</span>
                                    <span v-else class="text-danger">{{ $t('Explain...') }}</span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-2">
                <div class="helptooltip" v-if="hover_choice">
                    <strong>{{ hover_choice.control.name }}</strong><br>
                    {{ hover_choice.control.notes }}
                    <hr>
                    <strong>{{ hover_choice.choice.value_label }}</strong>
                    <div v-if="hover_definition.length">{{ hover_definition[0].description }}</div>
                </div>
            </div>
        </div>


        <!-- Modal -->
        <div v-if="show_modal" class="modal" id="exampleModal" tabindex="-1" style="display: block;">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{ $t('Set reason...') }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" @click="show_modal = false">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <textarea v-model="reason" class="form-control"></textarea>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="show_modal = false">{{ $t('Close') }}</button>
                <button type="button" class="btn btn-primary" @click="save_reason">{{ $t('Save changes') }}</button>
            </div>
            </div>
        </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios'
import QTooltip from './QTooltip.vue'
import AssetSwitch from './AssetSwitch.vue'

export default {
    name: 'Maturity',
    data() {
        return {
            account_id: MATURITY_DATA.account_id,
            asmt_id: MATURITY_DATA.asmt_id,
            assets: MATURITY_DATA.assets,
            choices: MATURITY_DATA.choices,
            controls: MATURITY_DATA.controls,
            definitions: MATURITY_DATA.definitions,
            // ---
            current_asset: MATURITY_DATA.assets[0],
            reason: "",
            reason_control: null,
            hover_choice: null,
            show_modal: false,
        }
    },
    computed: {
        selected_map() {
            var result = {}
            this.current_asset.maturity.forEach(m => {
                result[m.control_id] = m
            })
            return result
        },
        total_score() {
            if(this.current_asset.maturity.length != this.controls.length) {
                return null;
            }
            return this.current_asset.maturity.reduce((acc, m) => acc + m.score, 0)
        },
        hover_definition() {
            if (!this.hover_choice) return null
            return this.definitions.filter(item => item.choice_id == this.hover_choice.choice.id && item.control_id == this.hover_choice.control.id)
        }
    },
    methods:{
        is_selected(control, choice) {
            let item = this.selected_map[control.id]
            if (item == undefined) return false
            return (item.value == choice.value)
        },
        select(control, choice) {
            let answer = this.current_asset.maturity.find(m => m.control_id == control.id)
            if (answer == undefined) {
                answer = {control_id: control.id, value: choice.value, score: choice.score}
                this.current_asset.maturity.push(answer)
            } else {
                answer.value = choice.value
                answer.score = choice.score
            }

            axios
                .post(`/api/asmt/${this.account_id}/${this.asmt_id}/maturity/answer`, {
                    control_id: control.id,
                    asset_id: this.current_asset.id,
                    value: answer.value
                })
                .then(r => {
                    if (!r.data.success) {
                        alert('Falied to save answer')
                    }
                })
        },
        input_reason(control) {
            this.reason_control = control
            this.reason = this.selected_map[control.id].reason
            this.show_modal = true
        },
        save_reason() {
            this.show_modal = false
            this.selected_map[this.reason_control.id].reason = this.reason
            axios
                .post(`/api/asmt/${this.account_id}/${this.asmt_id}/maturity/reason`, {
                    control_id: this.reason_control.id,
                    asset_id: this.current_asset.id,
                    reason: this.reason,
                })
                .then(r => {
                    if (!r.data.success) {
                        alert('Falied to save reason')
                    }
                })
        }
    },
    components: {
        QTooltip,
        AssetSwitch,
    }

}
</script>


<style scoped>
    .helptooltip {
        position: fixed;
        right: 18px;
        top: 120px;
        z-index: 10;
        width: 280px;
        padding: 2px 6px;
        background-color: #fffeee;
        border: 1px solid #f0f0f0;
        border-radius: 4px;
        box-shadow: 3px 3px 3px #ccc;
        font-size: 0.8rem;
        white-space: pre-line;
    }
</style>
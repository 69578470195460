<template>
    <div class="compare">

        <div class="row">
            <div class="col-3">
                

                <a v-for="cat in compare_data.maturity_categories" 
                    @click="current_maturity_cat_id = cat.id"  
                    :class="{'btn-outline-primary': current_maturity_cat_id != cat.id, 'btn-primary': current_maturity_cat_id == cat.id}"
                    class="btn btn-block">
                        {{ cat.name }}
                </a>
            </div>
            <div class="col-9">
                <h3>
                    {{ maturity_category.name }}
                    <a v-for="asset in compare_data.assets" 
                        @click="current_asset = asset" 
                        :class="{'btn-outline-primary': current_asset.name != asset.name, 'btn-primary': current_asset.name == asset.name}"
                        class="btn ml-2">
                            {{ asset.name }}
                           
                    </a>
                </h3>
                
                
                <table  class="table table-sm">
                    <thead>
                        <tr>
                            <th style="width: 300px;"></th>
                            <th v-for="asmt in current_asset.assessments" style="width: 150px">
                                {{ asmt.name }}
                                <span class="float-right">
                                    <span style="font-weight: normal;">{{ $t('Criticality') }}:</span>
                                    {{ asmt.criticality_category }}
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="ctrl in maturity_controls">
                            <td>
                                {{ ctrl.name }}
                                </td>
                            <td v-for="asmt in current_asset.assessments">
                                <div :class="get_color_classes(maturity[asmt.id][ctrl.id])" class="p-1">
                                    {{ maturity[asmt.id][ctrl.id].value }}
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <th>{{ $t('Score') }}:</th>
                            <th v-for="asmt in current_asset.assessments" class="text-right">
                                {{ asset_total_score[asmt.id] }}
                            </th>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        
    </div>
</template>

<script>

import {COLORS} from './common.js'

export default {
    name: 'Compare',
    data() {
        return {
            compare_data: COMPARE_DATA,
            current_maturity_cat_id: COMPARE_DATA.maturity_categories[0].id,
            current_asset: COMPARE_DATA.assets[0]
        }
    },
    computed: {
        maturity_category() {
            return this.compare_data.maturity_categories.filter(cat => cat.id == this.current_maturity_cat_id)[0]
        },
        maturity_controls() {
            return this.compare_data.maturity_controls.filter(ctrl => ctrl.category_id == this.current_maturity_cat_id)
        },
        maturity() {
            let result = {}
            this.current_asset.assessments.forEach(asmt => {
                result[asmt.id] = {}
                this.maturity_controls.forEach(ctrl => result[asmt.id][ctrl.id] = this.get_maturity(asmt, ctrl.id))
            })
            return result
        },
        asset_total_score() {
            let result = {}
            this.current_asset.assessments.forEach(asmt => {
                let value = asmt.maturity
                    .filter(m => {
                        let ctrls = this.maturity_controls.filter(mc => mc.id == m.control_id)
                        return ctrls.length > 0
                    })
                    .reduce((total, m) => total + m.score, 0)
                result[asmt.id] = value.toFixed(2)
            })
            return result
        }
    },
    methods: {
        get_maturity(asmt, ctrl_id) {
            let found_items = asmt.maturity.filter(m => m.control_id == ctrl_id)
            if (found_items.length == 0) {
                return {'value': '----', 'color': 'none', 'score': 0}
            }
            return found_items[0]
        },
        get_color_classes(maturity) {
            return COLORS[maturity.color]
        }
    }

}
</script>

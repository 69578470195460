import Vue from 'vue'
import VueI18n from 'vue-i18n'

import nl from './locales/nl.json'
import de from './locales/de.json'
import fr from './locales/fr.json'

const messages = {
    nl,
    de,
    fr,
}

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: window.DJANGO_LANGUAGE || 'en', // set locale
    messages, // set locale messages
})

export default  i18n;

<template>
    <div class="rvr">
        <h2>{{ $t('Risk Vulnerabilities Remediation') }}</h2>

        <AssetSwitch :assets="assets" v-model="current_asset" />

        <div v-if="current_asset">
            {{ $t('Asset Impact Criticality') }}: 
                <strong>{{current_asset.criticality_score.toFixed(2) }} {{current_asset.criticality_category }}</strong>
            <table class="table table-hover table-sm mt-2">
                <thead>
                    <tr>
                        <th>{{ $t('Maturity') }}</th>
                        <th>{{ $t('Choice') }}</th>
                        <th>{{ $t('Action') }}</th>
                        <th colspan="3">{{ $t('Notes') }}</th>
                    </tr>
                </thead>
                <tbody v-for="(items, cat) in maturiteis_by_cateogry">
                    <tr class="bg-light">
                        <td colspan="6"><h4>{{ cat }}</h4></td>
                    </tr>
                    <tr v-for="maturity in items">
                        <td style="width: 300px">{{ maturity.control.name }}</strong></td>
                        <td style="width: 250px">
                            <div :class="get_color(maturity)" class="p-1">
                                {{ maturity.value }}
                            </div>
                            
                        </td>
                        <td style="width: 200px">
                            <select 
                                v-model="maturity.action"
                                @change="save(maturity)" 
                                class="form-control form-control-sm">
                                    <option :value="null">{{ $t('Select...') }}</option>
                                    <option :value="'accept'">{{ $t('Accept the risk') }}</option>
                                    <option :value="'take'">{{ $t('Take action') }}</option>
                            </select>
                        </td>
                        <td>
                            <textarea 
                                v-if="maturity.action" 
                                v-model="maturity.action_notes" 
                                @change="save(maturity)" 
                                class="form-control form-control-sm"
                                style="width: 400px; height: 70px"
                                :placeholder="$t('Notes...')">
                            </textarea>
                        </td>
                        <td>
                            <input 
                                v-if="maturity.action == 'take'"
                                v-model="maturity.action_responsible"
                                @change="save(maturity)"
                                class="form-control form-control-sm"
                                :placeholder="$t('Responsible...')">
                        </td>
                        <td>
                            <input 
                                v-if="maturity.action == 'take'"
                                v-model="maturity.action_due"
                                @change="save(maturity)"
                                class="form-control form-control-sm"
                                :placeholder="$t('Due...')">
                        </td>
                        
                    </tr>
                </tbody>
            </table>
        </div>
        <pre>


</pre>
    </div>

</template>

<script>

import axios from 'axios'
import AssetSwitch from './AssetSwitch.vue'
import {COLORS} from './common.js'

export default {
    name: 'RVR',
    data() {
        return {
            account_id: RVR_DATA.account_id,
            asmt_id: RVR_DATA.asmt_id,
            assets: RVR_DATA.assets,
            current_asset: RVR_DATA.assets[0],
        }
    },
    computed: {
        maturiteis_by_cateogry() {
            return this.current_asset.maturities.reduce((acc, value) => {
                if (!acc[value.control.category.name]) {
                    acc[value.control.category.name] = []
                }

                acc[value.control.category.name].push(value)

                return acc
            }, {})
        }
    },
    methods: {
        get_color(maturity) {
            return COLORS[maturity.color]
        },
        save(maturity) {
            axios
                .post(`/api/asmt/${this.account_id}/${this.asmt_id}/maturity/rvr`, {
                    maturity_id: maturity.id,
                    action: maturity.action,
                    action_responsible: maturity.action_responsible,
                    action_due: maturity.action_due,
                    action_notes: maturity.action_notes,
                })
                .then(r => {
                    if (!r.data.success) {
                        alert('Falied to save reason')
                    }
                })
        }
    },
    components: {
        AssetSwitch,
    }
}
</script>
